import React, { useEffect, useRef, useState } from "react";
//import cn from "classnames";

import gsap from "gsap";
import SplitText from "../utils/Split3.min";
import useOnScreen from "../hooks/useOnScreen";

//import "./style.scss";

export default function About() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);

  useEffect(() => {
    if (reveal) {
      const split = new SplitText("#headline", { type: "lines" });

      gsap.to(split.lines, {
        duration: 1,
        y: -20,
        opacity: 1,
        stagger: 0.1,
        ease: "power4.out",
        // onComplete: () => split.revert(),
      });
    }
  }, [reveal]);

  return (
    <section
     // className={cn("about-section", { "is-reveal": reveal })}
      className="about-section"
      data-scroll-section
    >
      {/* <h6 className='section-header'>
        ABOUT
      </h6> */}<div className="background-div">
      <p ref={ref} id="headline" 
      //className={cn({ "is-reveal": reveal })}>
      className="is-reveal">
        From uncertainty, I embarked on a life-transforming journey. With unwavering determination, I resigned from my job and immersed myself in a rigorous programming bootcamp. 
        Two weeks after graduating, I secured my first job as a programmer. 
        Yet, the journey wasn't over. Driven by a thirst for knowledge, I'm now on a self-directed mission to delve into the world of UI/UX design.
        My dream? I aim to leverage both design and programming as a UX Engineer, crafting inclusive and impactful experiences.
        This journey is fueled by passion, a testament to the transformative power of self-belief – even unsure beginnings lead to fulfilling destinations.
          </p>
      </div>
    </section>
  );
}
