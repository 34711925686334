import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import ReactTextTransition, { presets } from "react-text-transition";
export const texts = [
  "Front-end Developer",
  "UI/UX Designer",
  "Full-stack Developer"
];


const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const Banner = () => {
  const [playMarquee, setPlayMarquee] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPlayMarquee(true);
    }, 2000);
  }, []);

  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setTextIndex(prevIndex => {
        if (prevIndex >= texts.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 4000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <motion.div className='banner' variants={banner}>
      < BannerRowTop title={
        "My journey from uncertainty to"
      }
      />
      <section className="">
        <ReactTextTransition
          springConfig={presets.gentle}
          className="title-text"
          delay={300}
          inline
        >
          {texts[textIndex]}
        </ReactTextTransition>
      </section>
      {/* <BannerRowCenter title={""} playMarquee={playMarquee} /> */}
      {/* <BannerRowBottom title={"studio"} /> */}
    </motion.div>
  );
};

const AnimatedLetters = ({ title, disabled }) => (
  <motion.span
    className='row-title'
    variants={disabled ? null : banner}
    initial='initial'
    animate='animate'>
    {/* {[...title].map((letter) => ( */}
    <motion.span
      className='row-letter'
      variants={disabled ? null : letterAni}>
      {title}
    </motion.span>
    {/* ))} */}
  </motion.span>
);

const BannerRowTop = ({ title }) => {
  return (
    <div className={"banner-row"}>
      <div className='row-col'>
        <AnimatedLetters title={title} />
      </div>
      {/* <motion.div
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.4,
        }}
        className='row-col'>
        <span className='row-message'>
          We are specialised in setting up the foundation of your brand and
          setting you up for success.
        </span>
      </motion.div> */}
    </div>
  );
};

const BannerRowBottom = ({ title }) => {
  return (
    <div className={"banner-row center"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.95], duration: 1, delay: 1 }}
        className='scroll'>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}>
          scroll
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}>
          down
        </motion.span>
      </motion.div>
      <AnimatedLetters title={title} />
    </div>
  );
};

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}
        className='marquee__inner'>
        <AnimatedLetters title={"UX Engineer"} disabled />
        <AnimatedLetters title={"UI/UX Designer"} />
        <AnimatedLetters title={"Front-end Developer"} disabled />
        <AnimatedLetters title={"Full-stack Developer"} disabled />
        <AnimatedLetters title={"UI/UX Designer"} disabled />
        <AnimatedLetters title={"Front-end Developer"} disabled />
        <AnimatedLetters title={"Full-stack Developer"} disabled />
        <AnimatedLetters title={"UI/UX Designer"} disabled />
        <AnimatedLetters title={"Front-end Developer"} disabled />
        <AnimatedLetters title={"Full-stack Developer"} disabled />

      </motion.div>
    </div>
  );
};

export default Banner;
