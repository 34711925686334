import React from "react";

const Image = ({ src, alt }) => {
  return (
    <picture>
      <img src={src} alt={alt} width="200" height="150"/>
    </picture>
  );
};

export default Image;
