import { useEffect, useState } from "react";
import { motion, LayoutGroup, AnimatePresence } from "framer-motion";
import "./sass/main.scss";


import Loader from "./components/Loader";
import Header from "./components/Header";
import Banner from "./components/Banner";
import About from "./components/About";

function App() {
  const [loading, setLoading] = useState(true);
  return (
    <LayoutGroup type='crossfade'>
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <div className='background-div-2'>
            <Header />
            <Banner />
            {!loading && (
              <div className='transition-image final' >
                <motion.img
                  transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                  src={`./images/new-main.png`}
                  layoutId='main-image-1'
                />
              </div>
            )}
            <div>
              <About />
            </div>

          </div>
        )}
      </AnimatePresence>
    </LayoutGroup>
    // <div className="App">
    //   <header className="App-header">
    //     <img src="./images/image-5.png" alt="test"/>
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

  );
}

export default App;
